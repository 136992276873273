import React from 'react'
import { graphql, Link } from 'gatsby'
import get from 'lodash/get'
import Helmet from 'react-helmet'
import Layout from '../components/layout'
import header from '../images/CleanAir-Desktop-Header-1920x340-Curve-Disclaimer-2x.png'
import headerMobile from '../images/CleanAir-Mobile-Header-1080x550-Curve-Disclaimer-2x.png'
import people from '../images/people.png'

import { Container, Row, Col } from 'reactstrap'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTwitter, faFacebookF } from '@fortawesome/free-brands-svg-icons'

class fourohfourIndex extends React.Component {
  render() {
    const siteTitle = get(this, 'props.data.site.siteMetadata.title')

    return (
      <Layout location={this.props.location}>
        <div>
          <Helmet title={'404 | ' + siteTitle} />
          <div className="page-banner page-banner--fourohfour">
          <img className='d-none d-lg-block' src={header} alt="" />
            <img className='d-block d-lg-none' src={headerMobile} alt="" />            <h1>404</h1>
          </div>
          <Container className='mt-5 mb-5 pb-5'>
            <Row>
              <Col sm={12} lg={6} className='my-auto'>
               <h1>Uh oh!</h1>
               <p>It looks like something is wrong or the link is broken.</p>
              </Col>
              <Col sm={12} lg={6} className='mt-5 mt-lg-0'>
                <img className="w-100" src={people} />
              </Col>
            </Row>
          </Container>
        </div>
      </Layout>
    )
  }
}

export default fourohfourIndex

export const pageQuery = graphql`
  query fourohfourQuery {
    site {
      siteMetadata {
        title
      }
    }
  }
`
